<template>
  <b-modal
    id="modal-wi"
    :title="$t('modal.wi.title')"
    button-size="sm"
    @show="onShow"
  >
    <form
      ref="form"
      @submit.prevent="saveWI"
      v-if="!hasWI || this.mode == 'edit'"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            type="text"
            :label="$t('modal.wi.number')+ '*'"
          >
            <b-form-input
              type="text"
              class="form-control"
              id="name"
              v-model="form.wiRef"
              :placeholder="$t('filter.select')"
              @blur="$v.form.wiRef.$touch"
            ></b-form-input>
            <label
              class="error"
              v-if="!$v.form.wiRef.required && $v.form.wiRef.$dirty"
            >{{ $t('validate.required') }}
            </label>
            <label
              class="error"
              v-if="!$v.form.wiRef.maxLength && $v.form.wiRef.$dirty"
            >{{ $t('validate.maxLength', { val: '20' }) }}</label>
            <label
              class="error"
              v-if="!$v.form.wiRef.minLength && $v.form.wiRef.$dirty"
            >{{ $t('validate.minLength', { val: '1' }) }}</label>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group
            type="text"
            :label="$t('modal.wi.dueDate')"
          >
            <el-date-picker
              v-model="form.wiDueDate"
              type="date"
              :placeholder="$t('filter.select')"
              class="date-picker w-100"
            >
            </el-date-picker>
            <!-- <label
              class="error"
              v-if="!$v.form.wiDueDate.required && $v.form.wiDueDate.$dirty"
            >{{ $t('validate.required') }}
            </label> -->
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group
            id="input-group-entity"
            :label="$t('modal.wi.priority')"
          >
            <el-select
              v-model="form.wiPriority.value"
              :placeholder="$t('filter.select')"
              @blur="$v.form.wiPriority.value.$touch()"
              clearable
            >
              <el-option
                v-for="item in form.wiPriority.options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <!-- <label
              class="error"
              v-if="!$v.form.wiPriority.value.required && $v.form.wiPriority.value.$dirty"
            >
              {{ $t('validate.required') }}
            </label> -->
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <small class="text-muted">* {{ $t('validate.requiredFields') }}</small>
        </b-col>
      </b-row>
    </form>
    <div
      v-else
      class="ml-4"
    >
      <b-row class="mb-2">
        <b-col cols="3">{{ $t('modal.wi.ref') }}
        </b-col>
        <b-col class="text-primary">{{ this.$store.getters.selectedReportsData[0].wiRef }}</b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="3">{{ $t('modal.wi.priority') }} :</b-col>
        <b-col class="text-primary">{{ this.$store.getters.selectedReportsData[0].wiPriority }}</b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="3">{{ $t('modal.wi.dueDate') }} :</b-col>
        <b-col class="text-primary">{{ this.$store.getters.selectedReportsData[0].wiDueDate }}</b-col>
      </b-row>
    </div>
    <template #modal-footer="{ hide }">
      <b-button
        variant="outline-primary"
        size="sm"
        @click="edit"
        v-if="mode !== 'edit' && hasWI"
      >{{ $t('action.edit') }}</b-button>
      <b-button
        variant="outline-danger"
        size="sm"
        @click="removeWI"
        v-if="hasWI"
      >{{ $t('action.delete') }}</b-button>
      <b-button
        size="sm"
        variant="primary"
        @click="saveWI"
        v-if="!hasWI"
      >
        {{ $t('action.save') }}
      </b-button>
      <b-button
        size="sm"
        variant="primary"
        @click="saveWI"
        v-if="hasWI && mode == 'edit'"
      >
        {{ $t('action.save') }}
      </b-button>
      <b-button
        size="sm"
        variant="light"
        @click="hide()"
      >
        {{ $t('action.close') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import axiosResource from '@/utils/axiosResource';
import { globalSwal } from '@/plugins/globalSwal';
import swal from 'sweetalert2';
const swalBootsrap = swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary btn-swal',
    cancelButton: 'btn btn-light btn-swal',
  },
  buttonsStyling: false,
});

export default {
  name: 'modal-wi',
  data() {
    return {
      form: {
        wiRef: '',
        wiDueDate: '',
        wiPriority: {
          value: '',
          options: [
            { value: 'P1', label: 'P1' },
            { value: 'P2', label: 'P2' },
            { value: 'P3', label: 'P3' },
            { value: 'P4', label: 'P4' },
          ],
        },
      },
      mode: 'new',
    };
  },
  validations: {
    form: {
      wiRef: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(20),
      },
      wiDueDate: {},
      wiPriority: {
        value: {},
      },
      reportUuid: '',
    },
  },
  computed: {
    ...mapState({
      selectedReportsData: (state) => state.selectedReportsData,
    }),
    ...mapGetters(['selectedReports', 'reports']),
    hasWI() {
      return (
        this.$store.getters.selectedReportsData.length > 0 &&
        this.$store.getters.selectedReportsData[0].wiRef !== null
      );
    },
  },
  methods: {
    onShow() {
      this.$v.$reset();
      this.mode = 'new';
      this.form.wiRef = '';
      this.form.wiPriority.value = '';
      this.form.wiDueDate = '';
    },
    edit() {
      this.mode = 'edit';
      this.form.wiRef = this.$store.getters.selectedReportsData[0].wiRef;
      this.form.wiDueDate =
        this.$store.getters.selectedReportsData[0].wiDueDate;
      this.form.wiPriority.value =
        this.$store.getters.selectedReportsData[0].wiPriority;
    },
    removeWI() {
      const projectUuid = this.$store.getters.currentProject.uuid;

      // Show confirmation dialog
      swalBootsrap
        .fire({
          title: this.$t('modal.wi.removeTitle'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('true'),
          cancelButtonText: this.$t('false'),
        })
        .then((result) => {
          if (result.isConfirmed) {
            // User confirmed, proceed with setting variables to null and sending the request
            const userUuid = this.$store.getters.userUuid;
            const selectedReports = this.$store.getters.selectedReports;
            const wiRef = null;
            const wiDueDate = null;
            const wiPriority = null;

            axiosResource
              .put(
                `/reports/wi/${projectUuid}`,
                {
                  selectedReports,
                  wiRef,
                  wiDueDate,
                  wiPriority,
                },
                {
                  headers: {
                    'user-uuid': userUuid,
                  },
                }
              )
              .then(() => {
                const savedWI = {
                  wiRef: wiRef,
                  wiDueDate: wiDueDate,
                  wiPriority: wiPriority,
                };
                this.$store.commit('SET_WI', {
                  reportUuid: selectedReports[0], // Assuming you want to update the first selected report
                  wi: savedWI,
                });
                // Close modal
                this.$bvModal.hide('modal-wi');
                globalSwal.successSimple('success.done');
              })
              .catch((error) => {
                console.error('Failed to update WI', error);
                swalBootsrap.fire({
                  title: this.$t('error.title'),
                  text: this.$t('error.message'),
                  icon: 'error',
                });
              });
          }
        });
    },
    saveWI() {
      if (this.$v.form.$invalid) {
        this.$v.form.$touch();
        return;
      }

      const userUuid = this.$store.getters.userUuid;
      const projectUuid = this.$store.getters.currentProject.uuid;
      const selectedReports = this.$store.getters.selectedReports;
      const { wiRef, wiDueDate, wiPriority } = this.form;

      axiosResource
        .post(
          `/reports/wi/${projectUuid}`,
          {
            selectedReports,
            wiRef,
            wiDueDate,
            wiPriority: wiPriority.value,
          },
          {
            headers: {
              'user-uuid': userUuid,
            },
          }
        )
        .then(() => {
          const savedWI = {
            wiRef: wiRef,
            wiDueDate: wiDueDate,
            wiPriority: wiPriority.value,
          };
          this.$store.commit('SET_WI', {
            reportUuid: selectedReports[0], // Assuming you want to update the first selected report
            wi: savedWI,
          });
          // Close modal
          this.$bvModal.hide('modal-wi');
          globalSwal.successSimple('success.done');
        })
        .catch((error) => {
          console.error('Failed to update WI', error);
          swalBootsrap.fire({
            title: this.$t('error.title'),
            text: this.$t('error.message'),
            icon: 'error',
          });
        });
    },
  },
};
</script>

<style scoped>
.flag {
  padding-top: 2px;
  padding-bottom: 1px;
  padding-right: 12px;
  padding-left: 6px;
  border-radius: 3px;
}
.el-select {
  width: 100%;
}
</style>
