<template>
  <b-modal
    v-model="isVisible"
    title="Map"
    @shown="renderPdf"
    @hide="resetModalState"
    size="xl"
  >
    <div
      v-loading="loading"
      class="map-container"
    >
      <!-- Zoom controls inside the modal, centered at the bottom -->
      <div
        v-if="zoomVisible"
        id="zoom-container"
        class="d-flex justify-content-between"
      >
        <font-awesome-icon
          icon="fa-light fa-minus"
          size="2x"
          class="zoom-control"
          @click="zoomOut"
        />
        <span class="zoom-span">ZOOM</span>
        <font-awesome-icon
          icon="fa-light fa-plus"
          size="2x"
          class="zoom-control"
          @click="zoomIn"
        />
      </div>

      <div
        v-if="drawingUrl"
        class="canvas-wrapper"
        @mousedown="startDragging"
        @mousemove="onDragging"
        @mouseup="stopDragging"
        @mouseleave="stopDragging"
        @wheel="handleZoom"
      >
        <div
          :id="'canvas-container-' + canvasId"
          class="canvas-container"
          :style="{ transform: 'scale(' + zoomLevel + ')', transformOrigin: 'top left' }"
        >
          <canvas :id="'pdf-renderer-' + canvasId"></canvas>
          <div
            v-if="markerVisible"
            :style="{ top: markerY + '%', left: markerX + '%', transform: 'translate(-50%, -100%)' }"
            class="marker-icon"
          >
            <font-awesome-icon :icon="['fass', 'location-dot']" />
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import pdfjsLib from 'pdfjs-dist/build/pdf';
import 'pdfjs-dist/web/pdf_viewer.css';
pdfjsLib.GlobalWorkerOptions.workerSrc = 'pdfjs-dist/build/pdf.worker.js';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { Loading } from 'element-ui';

library.add(faLocationDot);

export default {
  name: 'ModalMap',
  components: {
    FontAwesomeIcon,
  },
  props: {
    drawingUrl: {
      type: String,
      required: true,
    },
    markerX: {
      type: Number,
      required: true,
    },
    markerY: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isVisible: false,
      pdf: null,
      canvasId: Math.random().toString(36).substr(2, 9), // unique ID for canvas
      scale: 1,
      canvas: {
        width: null,
        height: null,
      },
      markerVisible: false,
      loading: true,
      zoomLevel: 1, // Initial zoom level
      zoomStep: 0.1, // Step for zooming in/out
      maxZoom: 3, // Maximum zoom level
      minZoom: 0.5, // Minimum zoom level
      isDragging: false, // To track dragging state
      lastMouseX: 0,
      lastMouseY: 0,
      zoomVisible: true, // Controls visibility of zoom controls
    };
  },
  methods: {
    show() {
      this.isVisible = true;
    },
    hide() {
      this.isVisible = false;
      this.resetMarkerVisibility();
    },
    async renderPdf() {
      this.loading = true; // Start loading
      const loadingTask = pdfjsLib.getDocument(this.drawingUrl);
      loadingTask.promise.then((pdf) => {
        this.pdf = pdf;
        this.renderPage();
      });
    },
    renderPage() {
      this.pdf.getPage(1).then((page) => {
        let canvas = document.getElementById('pdf-renderer-' + this.canvasId);
        let ctx = canvas.getContext('2d');

        // Scale the PDF to fit within the container
        const viewport = page.getViewport({ scale: this.scale });

        canvas.width = viewport.width;
        canvas.height = viewport.height;
        this.canvas.width = viewport.width;
        this.canvas.height = viewport.height;

        const renderContext = {
          canvasContext: ctx,
          viewport: viewport,
        };

        page.render(renderContext).promise.then(() => {
          this.loading = false; // End loading
          this.markerVisible = true; // Set marker visible after PDF rendering
          this.positionMarker();
        });
      });
    },
    positionMarker() {
      const container = document.getElementById(
        'canvas-container-' + this.canvasId
      );
      const markerIcon = container.querySelector('.marker-icon');

      if (markerIcon) {
        const scrollToX =
          (this.markerX / 100) * this.canvas.width - container.clientWidth / 2;
        const scrollToY =
          (this.markerY / 100) * this.canvas.height -
          container.clientHeight / 2;

        container.scrollTo(scrollToX, scrollToY);
      }
    },
    resetMarkerVisibility() {
      this.markerVisible = false;
    },
    handleZoom(event) {
      if (event.deltaY < 0) {
        this.zoomIn();
      } else {
        this.zoomOut();
      }
      this.adjustScrollPosition();
    },
    zoomIn() {
      if (this.zoomLevel < this.maxZoom) {
        this.zoomLevel += this.zoomStep;
        this.adjustScrollPosition();
      }
    },
    zoomOut() {
      if (this.zoomLevel > this.minZoom) {
        this.zoomLevel -= this.zoomStep;
        this.adjustScrollPosition();
      }
    },
    // Adjust the scroll position after zoom to keep canvas in view
    adjustScrollPosition() {
      const wrapper = document.querySelector('.canvas-wrapper');
      const container = document.querySelector('.canvas-container');

      const newWidth = container.offsetWidth * this.zoomLevel;
      const newHeight = container.offsetHeight * this.zoomLevel;

      // Center the zoomed canvas
      const scrollToX = (newWidth - wrapper.clientWidth) / 2;
      const scrollToY = (newHeight - wrapper.clientHeight) / 2;

      wrapper.scrollLeft = scrollToX;
      wrapper.scrollTop = Math.max(scrollToY, 0); // Make sure the top of the canvas is visible
    },
    // Dragging functionality for moving the container
    startDragging(event) {
      this.isDragging = true;
      this.lastMouseX = event.clientX;
      this.lastMouseY = event.clientY;
    },
    onDragging(event) {
      if (this.isDragging) {
        const dx = event.clientX - this.lastMouseX;
        const dy = event.clientY - this.lastMouseY;

        const wrapper = document.querySelector('.canvas-wrapper');
        wrapper.scrollLeft -= dx;
        wrapper.scrollTop -= dy;

        this.lastMouseX = event.clientX;
        this.lastMouseY = event.clientY;
      }
    },
    stopDragging() {
      this.isDragging = false;
    },
    resetModalState() {
      // Reset zoom level and scroll position when modal is closed
      this.zoomLevel = 1;
      const wrapper = document.querySelector('.canvas-wrapper');
      if (wrapper) {
        wrapper.scrollLeft = 0;
        wrapper.scrollTop = 0;
      }
      this.resetMarkerVisibility();
    },
  },
};
</script>

<style scoped>
.map-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.canvas-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto; /* Enable scrolling */
  cursor: grab;
}

.canvas-container {
  width: 100%;
  height: 100%;
  cursor: grab; /* Add grab cursor */
}

.canvas-container:active {
  cursor: grabbing; /* Change cursor when dragging */
}

canvas {
  display: block;
  width: 100%;
  height: 100%;
}

.marker-icon {
  position: absolute;
  color: red;
  font-size: 24px;
  transform: translate(-50%, -100%);
}

/* Zoom control styles - fixed within modal */
#zoom-container {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999; /* Higher z-index to ensure it's above the canvas */
  border-radius: 30px;
  background-color: rgba(28, 28, 30, 0.7);
  padding: 20px;
  width: 180px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.zoom-control {
  color: #fff;
  cursor: pointer;
}

.zoom-span {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
</style>
