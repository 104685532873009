import axiosResource from '@/utils/axiosResource';
import swal from 'sweetalert2';
import moment from 'moment';
const swalBootstrap = swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary btn-swal',
    denyButton: 'btn btn-info btn-swal',
    cancelButton: 'btn btn-light btn-swal',
  },
  buttonsStyling: false,
});

export const reportActionMixin = {
  methods: {
    handleCommandPremiss(command, reportUuid) {
      const clgStyle = 'color: white;background-color: blue;';
      console.log('%c' + 'handleCommandPremiss', clgStyle);
      console.log('reportUuid', reportUuid);
      // Called from Article
      this.$store.commit('deleteSelection');

      this.$store.commit('selectReport', reportUuid);
      console.log(
        'selectReport',
        this.$store.commit('selectReport', reportUuid)
      );
      // Get selected report's data
      const reports = this.$store.state.reports;
      console.log('this.$store.state.reports', reports);
      const report = reports.reports.find(
        (report) => report.uuid === reportUuid
      );
      if (report) {
        this.$store.commit('selectReportData', report);
        console.log('selectReportData', report);
      }

      setTimeout(() => {
        this.handleCommand(command);
      }, 100);
    },
    async handleCommand(command) {
      const clgStyle = 'color: white;background-color: gray;';
      console.log('%c' + 'handleCommand: ' + command, clgStyle);

      // Called from Table
      // Get full reports for selection
      const reports = this.$store.getters.reports;
      const selectedReportUuids = this.$store.getters.selectedReports;
      const selectedReports = selectedReportUuids;
      if (selectedReportUuids.length > 1) {
        if (!this.$store.getters.selectionMode) {
          swalBootstrap
            .fire({
              title: this.$t('command.protect.moreThanOne'),
              text: this.$t('command.protect.instruction'),
              showCancelButton: true,
              confirmButtonText: this.$t('command.protect.enterSelectionMode'),
              icon: 'warning',
            })
            .then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                this.$store.commit('setSelectionMode', true);
              }
            });
          return;
        }
      }
      let selectedReportsFull = [];
      selectedReportUuids.forEach((uuid) => {
        const report = reports.find((element) => element.uuid == uuid);

        console.log(report);
        selectedReportsFull.push(report);
        console.log(selectedReportsFull);
      });

      if (selectedReportUuids.length != 0) {
        if (command == 'pdfSimple') {
          await this.generatePDFsSequentially(selectedReportsFull);
        } else if (command == 'pdfSynthesis') {
          // this.workOngoing();
          // Prevent action if >100 reports in selection
          const maxReports = 200;
          if (selectedReportUuids.length > maxReports) {
            swalBootstrap.fire({
              title: this.$t('command.protect.tooMany'),
              text: this.$t('command.protect.selectLessThan', {
                num: maxReports,
              }),
              confirmButtonText: 'OK',
              icon: 'warning',
            });
          } else {
            this.synthesisPDF(selectedReportUuids);
          }
        } else if (command == 'pdfSynthesis_OLD') {
          // this.workOngoing();
          // Prevent action if >100 reports in selection
          if (selectedReportUuids.length > 100) {
            swalBootstrap.fire({
              title: 'Too many reports',
              text: 'Select less than 150 reports',
              confirmButtonText: 'OK',
              icon: 'warning',
            });
          } else {
            this.synthesisPDF_OLD(selectedReportUuids);
          }
        } else if (command == 'xlsExport') {
          console.log(selectedReports.length);
          swalBootstrap
            .fire({
              title: this.$t('command.xlsExport', [selectedReports.length]),
              text: this.$t('command.xlsZip'),
              showDenyButton: true,
              showCancelButton: true,
              confirmButtonText: this.$t('command.withPhoto'),
              denyButtonText: this.$t('command.withoutPhoto'),
              icon: 'question',
            })
            .then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                this.xlsExport('withPhoto');
              } else if (result.isDenied) {
                this.xlsExport('withoutPhoto');
              }
            });
        } else if (command == 'CLOSED') {
          const selectedReportsData = this.$store.getters.selectedReportsData;
          console.log(selectedReportsData);
          const currentUser = this.$store.getters.loggedUser;
          console.log('currentUser', currentUser);

          // Get the nature of user on the current project
          const clgStyle = 'color: orange;font-size: 14px;';
          console.log('%c' + 'currentProject user NATURE:', clgStyle);
          console.log(this.$store.getters.currentProject);
          const userNatureNow = this.$store.getters.currentProject.nature;
          console.log('userNatureNow', userNatureNow);

          if (this.containsQuickReports(selectedReportsData)) {
            swalBootstrap.fire({
              title: this.$t('error.sorry'),
              text: this.$t('error.report.containsQuick'),
              showConfirmButton: true,
              icon: 'warning',
            });
          } else if (
            userNatureNow === 'FIRM' &&
            selectedReportsData.some((report) => report.wiRef !== null)
          ) {
            swalBootstrap.fire({
              title: this.$t('error.sorry'),
              text: this.$t('error.report.hasWiRef'),
              showConfirmButton: true,
              icon: 'warning',
            });
          } else {
            const updatedReports = this.canCloseReports(
              selectedReportsData,
              userNatureNow
            );
            let allReportsCanBeClosed = true;

            updatedReports.forEach((report) => {
              console.log(`Ref: ${report.reportRef}`, report.canClose);
              if (!report.canClose) {
                allReportsCanBeClosed = false;
                console.log(
                  `Access denied: Cannot close report with ref ${report.reportRef} made by a ${report.userNature}.`
                );
                swalBootstrap.fire({
                  icon: 'error',
                  title: this.$t('error.sorry'),
                  text: this.$t('error.report.closureNotAllowed'),
                });
              }
            });

            if (allReportsCanBeClosed) {
              console.log('All reports can be closed');
              this.changeStatus(command, selectedReportsData);
            }
          }
        } else if (command == 'DELETED') {
          const selectedReportsData = this.$store.getters.selectedReportsData;
          console.log(selectedReportsData);
          const currentUserUuid = this.$store.getters.loggedUser.uuid;
          console.log('currentUserUuid', currentUserUuid);

          // Check if the current user is the creator of all reports
          const notCreator = selectedReportsData.some(
            (report) => report.userUuid !== currentUserUuid
          );
          if (notCreator) {
            swalBootstrap.fire({
              icon: 'error',
              title: this.$t('error.sorry'),
              text: this.$t('error.report.deletionNotAllowed'),
            });
            return;
          } else {
            this.changeStatus(command, selectedReports);
          }
        } else if (command == 'ADDRESSED') {
          const clgStyle = 'color: white;background-color: red;';
          console.log('%c' + 'ADDRESSED', clgStyle);
          console.log(
            `this.$store.getters.selectedReports.length`,
            this.$store.getters.selectedReports.length
          );
          if (this.$store.getters.currentCard == 'table') {
            if (this.$store.getters.selectedReports.length == 1) {
              // Check if NO quick reports
              const selectedReportsData =
                this.$store.getters.selectedReportsData;
              console.log('selectedReportsData ADDRESS', selectedReportsData);
              selectedReportsData.forEach((report) => {
                if (report.interventionType == 'QUICK') {
                  swalBootstrap.fire({
                    title: this.$t('modal.address.quick.title'),
                    text: this.$t('modal.address.quick.text'),
                    showConfirmButton: true,
                    icon: 'warning',
                  });
                } else {
                  this.$bvModal.show('modal-address');
                }
              });
            } else {
              swalBootstrap.fire({
                title: 'Oops',
                text: this.$t('modal.address.tooMany'),
                showConfirmButton: true,
                icon: 'info',
              });
            }
          } else {
            const clgStyle = 'color: orange; font-size: 20px;';
            console.log('%c' + 'TABLE NOT SELECTED as currentCard', clgStyle);
            const selectedReportsData = this.$store.getters.selectedReportsData;
            console.log('selectedReportsData ADDRESS', selectedReportsData);
            this.$bvModal.show('modal-address');
          }
        } else if (command == 'SOLVED') {
          const selectedReportsData = this.$store.getters.selectedReportsData;
          if (this.containsQuickReports(selectedReportsData)) {
            swalBootstrap.fire({
              title: this.$t('error.sorry'),
              text: this.$t('error.report.containsQuick'),
              showConfirmButton: true,
              icon: 'warning',
            });
          } else {
            this.changeStatus(command, selectedReports);
          }

          // swalBootstrap
          //   .fire({
          //     title: this.$t('command.addPhoto', [selectedReports.length]),
          //     showDenyButton: true,
          //     showCancelButton: true,
          //     confirmButtonText: this.$t('true'),
          //     denyButtonText: this.$t('false'),
          //     icon: 'question',
          //   })
          //   .then((result) => {
          //     /* Read more about isConfirmed, isDenied below */
          //     if (result.isConfirmed) {
          //       this.notReady();
          //     } else if (result.isDenied) {
          //       this.processAction(command);
          //     }
          //   });
        } else if (command == 'WI') {
          const clgStyle = 'color: red;';
          console.log('%c' + 'command == "WI"', clgStyle);
          // if (this.$store.getters.currentCard == 'table') {
          if (this.$store.getters.selectedReports.length == 1) {
            console.log('%c' + 'length = 1', clgStyle);
            // Check if NO quick reports
            const selectedReportsData = this.$store.getters.selectedReportsData;
            console.log('selectedReportsData', selectedReportsData);
            selectedReportsData.forEach((report) => {
              console.log('%c' + 'loop', clgStyle);
              if (report.interventionType == 'QUICK') {
                console.log('%c' + 'no quick report', clgStyle);
                swalBootstrap.fire({
                  title: this.$t('modal.wi.quick.title'),
                  text: this.$t('modal.wi.quick.text'),
                  showConfirmButton: true,
                  icon: 'warning',
                });
              } else {
                console.log('show modal now');
                this.$bvModal.show('modal-wi');
              }
            });
          } else {
            swalBootstrap.fire({
              title: 'Oops',
              text: this.$t('modal.wi.tooMany'),
              showConfirmButton: true,
              icon: 'info',
            });
          }
          // }

          // swalBootstrap
          //   .fire({
          //     title: this.$tc('command.diAdd', selectedReports.length, {
          //       count: selectedReports.length,
          //     }),
          //     text: this.$t('command.diInstruction'),
          //     showDenyButton: true,
          //     showCancelButton: true,
          //     confirmButtonText: this.$t('command.diBHD'),
          //     denyButtonText: this.$t('command.diManual'),
          //     icon: 'question',
          //   })
          //   .then((result) => {
          //     /* Read more about isConfirmed, isDenied below */
          //     if (result.isConfirmed) {
          //       this.notReady();
          //     } else if (result.isDenied) {
          //       this.$bvModal.show('modal-wi');
          //     }
          //   });
        } else if (command == 'EDIT') {
          const selectedReportsData = this.$store.getters.selectedReportsData;
          console.log(selectedReportsData);
          const currentUserUuid = this.$store.getters.loggedUser.uuid;
          console.log('currentUserUuid', currentUserUuid);

          // Check if exactly one report is selected
          if (selectedReportsData.length !== 1) {
            swalBootstrap.fire({
              title: this.$t('error.sorry'),
              text: this.$t('error.report.multipleEdit'),
              showConfirmButton: true,
              icon: 'warning',
            });
          } else {
            const report = selectedReportsData[0];
            console.log(`Report UUID: ${report.uuid}`);

            if (report.status !== 'OPEN') {
              // Check if status is OPEN
              swalBootstrap.fire({
                title: this.$t('error.sorry'),
                text: this.$t('error.report.isNotOpen'),
                showConfirmButton: true,
                icon: 'warning',
              });
            } else if (this.containsQuickReports(selectedReportsData)) {
              // Check if the selected report contains a "QUICK" intervention type
              swalBootstrap.fire({
                title: this.$t('error.sorry'),
                text: this.$t('error.report.containsQuick'),
                showConfirmButton: true,
                icon: 'warning',
              });
            } else if (report.userUuid !== currentUserUuid) {
              // Check if the selected report was made by the current user
              swalBootstrap.fire({
                title: this.$t('error.sorry'),
                text: this.$t('error.report.notReportOwner'),
                showConfirmButton: true,
                icon: 'warning',
              });
            } else {
              // Proceed with edit logic if the uuid matches the current user's uuid and no "QUICK" reports
              console.log('Proceed with edit logic');
              // Add your edit logic here
              this.$router.push({
                name: 'create',
                params: { purpose: 'detailed', mode: 'edit', report: report },
              });
            }
          }
        } else if (command == 'COMMENT') {
          this.$bvModal.show('modal-comments');
          console.log(
            `this.$store.getters.selectedReports.length`,
            this.$store.getters.selectedReports.length
          );
          if (this.$store.getters.currentCard == 'table') {
            if (this.$store.getters.selectedReports.length == 1) {
              // Check if NO quick reports
              const selectedReportsData =
                this.$store.getters.selectedReportsData;
              selectedReportsData.forEach((report) => {
                if (report.interventionType == 'QUICK') {
                  swalBootstrap.fire({
                    title: this.$t('modal.address.quick.title'),
                    text: this.$t('modal.address.quick.text'),
                    showConfirmButton: true,
                    icon: 'warning',
                  });
                } else {
                  this.$bvModal.show('modal-comments');
                }
              });
            } else {
              swalBootstrap.fire({
                title: 'Oops',
                text: this.$t('modal.address.tooMany'),
                showConfirmButton: true,
                icon: 'info',
              });
            }
          } else {
            // TABLE NOT SELECTED as currentCard
            this.$bvModal.show('modal-comments');
          }
        } else if (command == 'ATTACHMENT') {
          this.notReady();
        } else {
          this.changeStatus(command, selectedReports);
          // swalBootstrap
          //   .fire({
          //     title: this.$tc(
          //       'modal.changeStatus.setReportStatus',
          //       selectedReports.length,
          //       {
          //         count: selectedReports.length,
          //         status: this.$t(`status.${command}`),
          //       }
          //     ),
          //     showConfirmButton: true,
          //     showCancelButton: true,
          //     icon: 'question',
          //   })
          //   .then((result) => {
          //     if (result.isConfirmed) {
          //       this.processAction(command);
          //     } else if (result.isDenied) {
          //       swalBootstrap.fire('Changes are not saved', '', 'info');
          //     }
          //   });
        }
      } else {
        swalBootstrap.fire({
          title: `Selection is empty`,
          text: `Please select one or several reports.`,
          icon: 'warning',
          showConfirmButton: true,
        });
      }
    },
    containsQuickReports(selectedReportsData) {
      return selectedReportsData.some(
        (report) => report.interventionType === 'QUICK'
      );
    },
    canCloseReports(selectedReportsData, userNatureNow) {
      return selectedReportsData.map((report) => {
        const { userNature } = report;

        switch (userNatureNow) {
          case 'BM':
            // BM cannot close reports made by CONTROLLER
            if (userNature === 'CONTROLLER') {
              return { ...report, canClose: false };
            }
            return { ...report, canClose: true };
          case 'CONTROLLER':
            // Only CONTROLLER can close reports made by CONTROLLER
            return { ...report, canClose: true };
          case 'FIRM':
            // FIRM cannot close reports made by CONTROLLER
            if (userNature === 'CONTROLLER') {
              return { ...report, canClose: false };
            }
            return { ...report, canClose: true };
          default:
            // Default to denying access if the role is not recognized
            return { ...report, canClose: false };
        }
      });
    },
    changeStatus(command, selectedReports) {
      swalBootstrap
        .fire({
          title: this.$tc(
            'modal.changeStatus.setReportStatus',
            selectedReports.length,
            {
              count: selectedReports.length,
              status: this.$t(`status.${command}`),
            }
          ),
          showConfirmButton: true,
          showCancelButton: true,
          icon: 'question',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.processAction(command);
          } else if (result.isDenied) {
            swalBootstrap.fire('Changes are not saved', '', 'info');
          }
        });
    },
    notReady() {
      swalBootstrap.fire({
        title: 'Oops',
        text: this.$t('error.notReady'),
        showConfirmButton: true,
        icon: 'info',
      });
    },
    workOngoing() {
      swalBootstrap.fire({
        title: this.$t('error.workOngoing.title'),
        text: this.$t('error.workOngoing.text'),
        showConfirmButton: true,
        icon: 'info',
      });
    },
    // addWI() {
    //   console.log('add WI');
    //   const selectedReports = this.$store.getters.selectedReports;
    // },
    processAction(action) {
      const projectRef = this.$store.getters.currentProject.ref;
      const selectedReports = this.$store.getters.selectedReports;
      const projectUuid = this.$store.getters.currentProject.uuid;

      axiosResource
        .post(`/reports/action/${projectUuid}/${action}`, {
          data: {
            projectRef,
            selectedReports,
          },
        })
        .then((res) => {
          console.log(res.data.msg);
          console.log(res.data.results);
          if (res.data.success) {
            swalBootstrap
              .fire({
                title: this.$t('success.done'),
                showConfirmButton: false,
                icon: 'success',
                timer: 1500,
              })
              .then(() => {
                this.$store.dispatch('changeStatus', action);
                // If action processed from Article, then delete selection in store
                if (this.$store.getters.currentCard != 'table') {
                  this.$store.commit('deleteSelection');
                }
              });
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$globalSwal.error(err.response.data.msg);
        });
    },
    areAllWiRefSimilar(selectedReportsFull) {
      if (selectedReportsFull.length === 0) {
        return true; // If the array is empty, consider them as similar
      }

      const firstWiRef = selectedReportsFull[0].wiRef;

      for (let i = 1; i < selectedReportsFull.length; i++) {
        if (selectedReportsFull[i].wiRef !== firstWiRef) {
          return false;
        }
      }

      return true;
    },
    showHistory(reportUuid, reportRef) {
      this.$store.commit('setReportUuid', reportUuid);
      this.$store.commit('setReportRef', reportRef);
      // this.$refs.modalHistory.reportId = reportId;
      this.$bvModal.show('modal-history');
    },
    pdfSpinner(reportUuid, set) {
      const iconPdf = document.getElementById('iconPdf-' + reportUuid);
      const spinnerPdf = document.getElementById('spinnerPdf-' + reportUuid);
      if (
        typeof iconPdf != 'undefined' &&
        iconPdf != null &&
        typeof spinnerPdf != 'undefined' &&
        spinnerPdf != null
      ) {
        switch (set) {
          case 'ON':
            iconPdf.style.display = 'none';
            spinnerPdf.style.display = 'block';
            break;
          case 'OFF':
            iconPdf.style.display = 'block';
            spinnerPdf.style.display = 'none';
            break;
          default:
            break;
        }
        iconPdf;
      }
    },
    async servePDF(report) {
      const projectUuid = this.$store.getters.currentProject.uuid;
      const fileName = `${report.interventionType}-${report.buildingName}${report.level}${report.zone}${report.serial}${report.definition}_${report.reportRef}.pdf`;
      this.loading = true;

      this.pdfSpinner(report.uuid, 'ON');

      try {
        const response = await axiosResource.get(
          `/serve/${projectUuid}/${report.uuid}/${fileName}`,
          {
            headers: {
              Accept: 'application/pdf',
            },
            responseType: 'blob',
          }
        );

        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // Clean up
        this.loading = false;
        this.pdfSpinner(report.uuid, 'OFF');
      } catch (error) {
        this.loading = false;
        this.$globalSwal.error(
          'An error occurred while trying to fetch the PDF.'
        );
        // if (error.response && error.response.status === 404) {
        //   console.log(
        //     'File not found, generating PDF:',
        //     error.response.data.msg
        //   );
        //   this.simplePDF(report.uuid);
        // } else {
        //   console.error('Error fetching PDF:', error.toString());
        //   this.$globalSwal.error(
        //     'An error occurred while trying to fetch the PDF.'
        //   );
        // }
      }
    },

    async generatePDFsSequentially(reports) {
      for (const report of reports) {
        await this.servePDF(report);
      }
    },
    // simplePDF(reportUuid) {
    //   console.log('HERE in simplePDF()');

    //   // Handle spinner: ON
    //   this.pdfSpinner(reportUuid, 'ON');
    //   this.loading = true;
    //   const projectUuid = this.$store.getters.currentProject.uuid;
    //   const projectRef = this.$store.getters.currentProject.ref;

    //   axiosResource
    //     .get(`/pdf/simple/${projectUuid}/${projectRef}/${reportUuid}`, {
    //       responseType: 'arraybuffer',
    //     })
    //     .then((res) => {
    //       const reports = this.$store.getters.reports;
    //       const report = reports.find((el) => el.uuid === reportUuid);
    //       const fileName = `${report.interventionType}-${report.buildingName}${report.level}${report.zone}${report.serial}${report.definition}_${report.reportRef}`;

    //       // Correct MIME type for PDF
    //       let blob = new Blob([res.data], { type: 'application/pdf' });
    //       let url = window.URL.createObjectURL(blob);
    //       let a = document.createElement('a');
    //       document.body.appendChild(a);
    //       a.style = 'display: none';
    //       a.href = url;
    //       a.download = fileName + '.pdf';
    //       a.click();
    //       window.URL.revokeObjectURL(url);

    //       setTimeout(() => {
    //         this.loading = false;
    //       }, 500);
    //       // Handle spinner: OFF
    //       this.pdfSpinner(reportUuid, 'OFF');
    //     })
    //     .catch((err) => {
    //       // Handle spinner: OFF
    //       this.pdfSpinner(reportUuid, 'OFF');
    //       this.loading = false;
    //       this.$globalSwal.error(err.response.data.msg);
    //     });
    // },

    synthesisPDF(reportUuids) {
      this.$message({
        message: this.$t('message.workingOnIt'),
      });
      this.loading = true;
      const projectUuid = this.$store.getters.currentProject.uuid;
      const projectRef = this.$store.getters.currentProject.ref;
      const initials = this.$store.getters.loggedUser.initials;

      axiosResource
        .get(
          `/pdf/synthesis/${projectUuid}/${projectRef}/${reportUuids}/${initials}`,
          {
            responseType: 'arraybuffer',
            timeout: 10 * 60 * 1000, // Set timeout to 10 minutes (600000 ms)
          }
        )
        .then((res) => {
          // Extract the filename from the Content-Disposition header
          const contentDisposition = res.headers['content-disposition'];
          let fileName = 'download.zip'; // Default name in case it's not provided
          // if (contentDisposition) {
          //   const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
          //   if (fileNameMatch && fileNameMatch.length > 1) {
          //     fileName = fileNameMatch[1];
          //   }
          // }

          // Create a blob and download the ZIP file using the provided filename
          let blob = new Blob([res.data], { type: 'application/zip' });
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;

          // Log the entire error object
          console.log('Full error object:', err);

          // Log specific response details if available
          if (err.response) {
            console.log('Error response:', err.response); // Check if response object exists
            console.log('Error response status:', err.response.status); // Status code
            console.log('Error response data:', err.response.data); // The response data from server
          }

          if (err.response && err.response.data && err.response.data.msg) {
            this.$globalSwal.error(err.response.data.msg); // Show error message from server
          } else if (err.response.status == 400) {
            this.$globalSwal.error('error.oneFloorOnly');
          } else {
            this.$globalSwal.error('An unknown error occurred.');
          }
        });
    },
    synthesisPDF_OLD(reportUuids) {
      this.$message({
        message: 'Working on it!',
      });
      this.loading = true;
      const projectUuid = this.$store.getters.currentProject.uuid;
      const projectRef = this.$store.getters.currentProject.ref;
      const initials = this.$store.getters.loggedUser.initials;

      axiosResource
        .get(
          `/pdf/synthesis/old/${projectUuid}/${projectRef}/${reportUuids}/${initials}`,
          {
            responseType: 'arraybuffer',
          }
        )
        .then((res) => {
          // Extract the filename from the Content-Disposition header
          const contentDisposition = res.headers['content-disposition'];
          let fileName = 'download.zip'; // Default name in case it's not provided
          if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
            if (fileNameMatch && fileNameMatch.length > 1) {
              fileName = fileNameMatch[1];
            }
          }

          // Create a blob and download the ZIP file using the provided filename
          let blob = new Blob([res.data], { type: 'application/zip' });
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;

          // Log the entire error object
          console.log('Full error object:', err);

          // Log specific response details if available
          if (err.response) {
            console.log('Error response:', err.response); // Check if response object exists
            console.log('Error response status:', err.response.status); // Status code
            console.log('Error response data:', err.response.data); // The response data from server
          }

          if (err.response && err.response.data && err.response.data.msg) {
            this.$globalSwal.error(err.response.data.msg); // Show error message from server
          } else if (err.response.status == 400) {
            this.$globalSwal.error('error.oneFloorOnly');
          } else {
            this.$globalSwal.error('An unknown error occurred.');
          }
        });
    },
    xlsExport(option) {
      this.loading = true;
      const projectRef = this.$store.getters.currentProject.ref;
      const projectUuid = this.$store.getters.currentProject.uuid;
      const selectedReports = this.$store.getters.selectedReports;
      const userUuid = this.$store.getters.loggedUser.uuid;
      axiosResource
        .post(
          `/reports/xlsExport/${projectUuid}/${option}`,
          {
            data: {
              projectRef,
              selectedReports,
              userUuid,
            },
          },
          {
            responseType: 'blob',
          }
        )
        .then((res) => {
          this.loading = false;
          let fileName;
          if (option == 'withPhoto') {
            fileName = `GOSO-${projectRef}_${moment().format(
              'YYYYMMDD-HHMMSS'
            )}.zip`;
            console.log('with photo');
          } else {
            fileName = `GOSO-${projectRef}_${moment().format(
              'YYYYMMDD-HHMMSS'
            )}.xlsx`;
            console.log('WITHOUT photo');
          }

          const url = URL.createObjectURL(
            new Blob([res.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.$globalSwal.error(err.response.data.msg);
        });
    },
  },
};
