<template>
  <div>
    <b-row v-if="!editRef">
      <b-col
        cols="1"
        lg="1"
      >
        <h3 class="localization-green">
          <font-awesome-icon
            class="mr-3"
            icon="fa-thin fa-bullseye-pointer"
          />
        </h3>
      </b-col>
      <b-col
        cols="11"
        lg="5"
      >
        <h3 class="localization-green ml-2">
          {{ localRef(buildingValue, levelValue, zoneValue, serial, definitionValue) }}
        </h3>
      </b-col>
      <b-col
        cols="1"
        lg="1"
      ></b-col>
      <b-col
        cols="9"
        lg="5"
      >
        <el-button
          type="text"
          icon="el-icon-edit"
          @click="editRef = true"
          v-if="purpose == 'detailed'"
        >
          {{ $t('create.text.change') }}
        </el-button>
      </b-col>
    </b-row>
    <div v-if="editRef">
      <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <b-form-group id="input-group-3">
            <el-select
              class="w-100"
              v-model="buildingValue"
              filterable
              clearable
              placeholder="Bâtiment"
              @change="buildingChange"
              :disabled="mode === 'edit'"
            >
              <el-option
                v-for="item in buildingOptions"
                :key="item.uuid"
                :label="item.ref"
                :value="item.uuid"
              ></el-option>
            </el-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <b-form-group id="input-group-3">
            <el-select
              class="w-100"
              v-model="levelValue"
              filterable
              clearable
              placeholder="Niveau"
              @change="levelChange"
              :disabled="mode === 'edit'"
            >
              <el-option
                v-for="item in levelOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group id="input-group-3">
            <el-select
              class="w-100"
              v-model="zoneValue"
              filterable
              clearable
              placeholder="Zone"
              @change="zoneChange"
            >
              <el-option
                v-for="item in zoneOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group id="input-group-2">
            <el-input
              placeholder="Serial"
              v-model="serial"
              clearable
              @change="serialChange"
            ></el-input>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group id="input-group-3">
            <el-select
              class="w-100"
              v-model="definitionValue"
              filterable
              clearable
              placeholder="Définition"
              @change="definitionChange"
            >
              <el-option
                v-for="item in definitionOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <b-row
      class="mt-4"
      v-if="purpose == 'detailed'"
    >
      <b-col cols="12">
        <b-form-group
          :label="$t('create.text.locationHint')"
          class="h4"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="locationValue"
            :options="locationOptions"
            value-field="value"
            text-field="label"
            :aria-describedby="ariaDescribedby"
            name="flavour-1"
          ></b-form-checkbox-group>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>


<script>
import axiosResource from '@/utils/axiosResource';
import levels from '../../assets/data/levels';
import zones from '../../assets/data/zones';
import definitions from '../../assets/data/definitions';
import { localRefMixin } from '../../mixins/localRefMixin';

export default {
  name: 'localisation-reference',
  props: {
    purpose: String,
    report: Object,
    mode: String,
  },
  mixins: [localRefMixin],
  data() {
    return {
      editRef: false,
      buildingValue: '',
      buildingOptions: [],
      levelOptions: levels,
      levelValue: '',
      zoneOptions: zones,
      zoneValue: '',
      serial: '',
      definitionOptions: definitions,
      definitionValue: '',
      locationOptions: [
        { value: 'locationInt', label: this.$t('location.locationInt') },
        { value: 'locationExt', label: this.$t('location.locationExt') },
        {
          value: 'locationCeiling',
          label: this.$t('location.locationCeiling'),
        },
        { value: 'locationFloor', label: this.$t('location.locationFloor') },
        { value: 'locationFacade', label: this.$t('location.locationFacade') },
        { value: 'locationRoof', label: this.$t('location.locationRoof') },
        {
          value: 'locationServiceShaft',
          label: this.$t('location.locationServiceShaft'),
        },
      ],
      locationValue: [],
    };
  },
  methods: {
    getBuildings() {
      this.loading = true;
      this.projectUuid = this.$store.getters.currentProject.uuid;
      axiosResource
        .get('/admin/buildings/building/accessLess/' + this.projectUuid)
        .then((res) => {
          this.buildingOptions = res.data;
        })
        .catch((err) => {
          this.loading = false;
          this.$globalSwal.error(err.response.data.msg);
        });
    },
    setFilters() {
      const clgStyle = 'color: red;';
      console.log('%c' + 'setFilters', clgStyle);
      console.log(`mode= ${this.mode}`);
      console.log(`purpose= ${this.purpose}`);
      console.log(this.report);
      if (this.mode === 'edit' && this.report) {
        // Setting values from report
        this.buildingValue = this.report.buildingName;
        this.levelValue = this.report.level;
        this.zoneValue = this.report.zone;
        this.serial = this.report.serial;
        this.definitionValue = this.report.definition;
      } else {
        // Setting values from store
        this.buildingValue = this.$store.getters.building.label;
        this.levelValue = this.$store.getters.level;
        this.zoneValue = this.$store.getters.zone;
        this.serial = this.$store.getters.serial;
        this.definitionValue = this.$store.getters.definition.value;
      }
      console.log('%cBuilding Value:', clgStyle, this.buildingValue);
      console.log('%cLevel Value:', clgStyle, this.levelValue);
      console.log('%cZone Value:', clgStyle, this.zoneValue);
      console.log('%cSerial:', clgStyle, this.serial);
      console.log('%cDefinition:', clgStyle, this.definitionValue);
    },
    buildingChange(uuid) {
      if (uuid !== '') {
        const item = this.buildingOptions.filter((item) => item.uuid === uuid);
        const ref = item[0].ref;
        this.$store.commit('setBuilding', { uuid: uuid, label: ref });
      } else {
        this.$store.commit('setBuilding', { uuid: null, label: null });
      }
    },
    levelChange(val) {
      this.$store.commit('setLevel', val);
    },
    zoneChange(val) {
      this.$store.commit('setZone', val);
    },
    serialChange(val) {
      this.$store.commit('setSerial', val);
    },
    definitionChange(val) {
      if (val !== '') {
        const item = this.definitionOptions.filter(
          (item) => item.label === val
        );
        const label = item[0].label;
        this.$store.commit('setDefinition', { value: val, label: label });
      } else {
        this.$store.commit('setDefinition', { value: null, label: null });
      }
    },
    initializeLocationValues() {
      const locations = [
        { value: 'locationInt', label: this.$t('location.locationInt') },
        { value: 'locationExt', label: this.$t('location.locationExt') },
        {
          value: 'locationCeiling',
          label: this.$t('location.locationCeiling'),
        },
        { value: 'locationFloor', label: this.$t('location.locationFloor') },
        { value: 'locationFacade', label: this.$t('location.locationFacade') },
        { value: 'locationRoof', label: this.$t('location.locationRoof') },
        {
          value: 'locationServiceShaft',
          label: this.$t('location.locationServiceShaft'),
        },
      ];

      this.locationOptions = locations.map((location) => {
        return { value: location.value, label: location.label };
      });

      // Initialize the location values based on the report
      if (this.report) {
        this.locationValue = locations
          .filter((location) => this.report[location.value] === 1)
          .map((location) => location.value);
      }
    },
  },
  watch: {
    report: {
      immediate: true,
      handler(newReport) {
        if (newReport) {
          this.setFilters();
          this.initializeLocationValues();
        }
      },
    },
    serial(val) {
      this.$store.commit('setSerial', val);
    },
  },
  created() {
    console.log(`mode= ${this.mode}`);
    console.log(`purpose= ${this.purpose}`);
    console.log(this.report);
    this.getBuildings();
    // Ensure filters are set if report is already available
    if (this.report) {
      this.setFilters();
      this.initializeLocationValues();
    } else {
      this.setFilters();
    }
  },
};
</script>
