<template>
  <!-- <div class="container"> -->
  <b-container>
    <idle-timer></idle-timer>
    <!-- DESKTOP BUTTONS -->
    <div class="col-md-3 col-lg-3 col-xl-3 sticky pt-5 d-none d-sm-none d-md-block d-lg-block d-xl-block float-right">
      <div class="btn-group-vertical">
        <button
          v-if="purpose == 'detailed' && mode == 'new'"
          @click="publishDetailed"
          class="btn btn-primary btn-lg d-block d-inline-block d-md-inline"
        >{{ $t('action.publish') }}</button>
        <button
          v-if="(purpose == 'like' || purpose == 'visited' || purpose == 'locked' || purpose == 'busy' ) && mode == 'new'"
          @click="publishQuick"
          class="btn btn-primary btn-lg d-block d-inline-block d-md-inline"
        >{{ $t('action.publish') }}</button>
        <button
          v-if="purpose == 'detailed' && mode == 'edit'"
          @click="editDetailed"
          class="btn btn-danger btn-lg d-block d-inline-block d-md-inline"
        >{{ $t('action.edit') }}</button>
        <button
          v-if="(purpose == 'like' || purpose == 'visited' || purpose == 'locked' || purpose == 'busy' ) && mode == 'edit'"
          @click="editQuick"
          class="btn btn-danger btn-lg d-block d-inline-block d-md-inline"
        >{{ $t('action.edit') }}</button>
        <button
          type="button"
          class="btn btn-secondary btn-lg d-block d-inline-block d-md-inline"
          @click="$router.push({ name: 'Explore' })"
        >{{ $t('action.cancel') }}</button>
      </div>
    </div>

    <div class="create-container">
      <b-col
        cols="12"
        md="9"
      >

        <!-- GENERAL -->

        <h1
          class="text-danger"
          v-if="mode == 'edit'"
        >{{ $t('report.editAlert') }} <b>{{ report.reportRef }}</b></h1>

        <h1
          v-if="mode != 'edit'"
          class="my-4"
        >{{ $t('create.title.general') }} </h1>

        <General
          ref="general"
          :purpose="purpose"
          :report="report"
          :mode="mode"
          @intervention-date-updated="clearInterventionDateError"
        />

        <div
          v-if="interventionDateError"
          class="text-danger"
        ><font-awesome-icon
            :icon="['fad', 'circle-exclamation']"
            size="2xl"
          /><span class="error-create ml-2">{{ interventionDateError }}</span></div>

        <!-- UPLOAD IMAGE -->
        <div v-if="purpose == 'detailed'">
          <h1 class="my-4">{{ $t('create.title.photo') }}</h1>
          <Dropzone
            id="dropzone"
            ref="dropzone"
            :mode="mode"
            :report="report"
            @files-changed="clearPhotoError"
          />
        </div>

        <div
          v-if="photoError"
          class="text-danger mt-3"
        ><font-awesome-icon
            :icon="['fad', 'circle-exclamation']"
            size="2xl"
          /><span class="error-create ml-2">{{ photoError }}</span></div>

        <!-- LOCALISTAION -->

        <h1 class="my-4">{{ $t('create.title.location') }}</h1>

        <LocalisationReference
          ref="localisationReference"
          :purpose="purpose"
          :report="report"
          :mode="mode"
        />

        <LocationMap
          id="locationMap"
          ref="locationMap"
          :mode="mode"
          :report="report"
          @location-updated="clearLocationError"
        />

        <div
          v-if="locationError"
          class="text-danger"
        ><font-awesome-icon
            :icon="['fad', 'circle-exclamation']"
            size="2xl"
          /><span class="error-create ml-2">{{ locationError }}</span></div>

        <!-- PROGRAM -->
        <div v-if="purpose == 'detailed'">
          <h1 class="my-4">{{ $t('create.title.description') }}</h1>
          <Program
            ref="program"
            :report="report"
            :mode="mode"
          />
        </div>

        <!-- PRIORITY  -->
        <div v-if="purpose == 'detailed'">
          <h1 class="my-4">{{ $t('create.title.result') }}</h1>
          <Priority
            ref="priority"
            :report="report"
            :mode="mode"
          />
        </div>

        <!-- SAFETY MARGIN -->
        <div class="safety-margin"></div>

      </b-col>
    </div>

    <!-- MOBILE BOTTOM CONTROLS -->
    <div class="fixed-bottom d-block d-sm-none btn-container">
      <b-row>
        <b-col>

          <el-button
            class="btn-block btn-jumbo btn-white-blue-text"
            @click="$router.push({ name: 'Explore' })"
          >{{ $t('action.cancel') }}</el-button>

        </b-col>
        <b-col>

          <el-button
            type="primary"
            class="btn-block btn-jumbo"
            v-if="purpose == 'detailed' && mode == 'new'"
            @click="publishDetailed"
          >{{ $t('action.publish') }}</el-button>

          <el-button
            type="primary"
            class="btn-block btn-jumbo"
            v-if="(purpose == 'like' || purpose == 'visited' || purpose == 'locked' || purpose == 'busy' ) && mode == 'new'"
            @click="publishQuick"
          >{{ $t('action.publish') }}</el-button>

          <el-button
            type="success"
            class="btn-block btn-jumbo"
            v-if="purpose == 'detailed' && mode == 'edit'"
            @click="editDetailed"
          >{{ $t('action.edit') }}</el-button>

          <el-button
            type="danger"
            class="btn-block btn-jumbo"
            v-if="(purpose == 'like' || purpose == 'visited' || purpose == 'locked' || purpose == 'busy' ) && mode == 'edit'"
            @click="editQuick"
          >{{ $t('action.edit') }}</el-button>

        </b-col>
      </b-row>
    </div>

  </b-container>
  <!-- </div> -->
</template>
<script>
import axiosResource from '@/utils/axiosResource';
import IdleTimer from '../../components/IdleTimer.vue';
import General from '../../components/Create/General.vue';
import Dropzone from '../../components/Create/Dropzone.vue';
import LocalisationReference from '../../components/Create/LocalisationReference.vue';
import LocationMap from '../../components/Create/LocationMap.vue';
import Program from '../../components/Create/Program.vue';
import Priority from '../../components/Create/Priority.vue';
import swal from 'sweetalert2';
import { mapGetters } from 'vuex';

const swalBootsrap = swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary btn-swal',
    cancelButton: 'btn btn-light btn-swal',
  },
  buttonsStyling: false,
});

export default {
  name: 'Create',
  components: {
    IdleTimer,
    General,
    Dropzone,
    LocalisationReference,
    LocationMap,
    Program,
    Priority,
  },
  data() {
    return {
      mode: this.$route.params.mode,
      purpose: this.$route.params.purpose,
      report: this.$route.params.report,
      projectUuid: this.$store.getters.currentProject.uuid,
      locationError: null,
      interventionDateError: null,
      photoError: null,
    };
  },
  computed: {
    ...mapGetters(['loading']),
  },
  mounted() {
    console.log('Report parameter (mounted):', this.$route.params.report);
    // window.location.reload();
  },
  methods: {
    validateLocation() {
      const marker = this.$refs.locationMap.marker.percent;
      console.log('marker', marker);
      if (marker.x == null || marker.y == null) {
        this.locationError = this.$t('error.locationRequired');
        return false;
      }
      this.locationError = null;
      return true;
    },
    clearLocationError() {
      this.locationError = null;
    },
    validateInterventionDate() {
      console.log('validateInterventionDate');
      const interventionDate = this.$refs.general.interventionDate;
      if (!interventionDate) {
        this.interventionDateError = this.$t('error.interventionDateRequired');
        return false;
      }
      this.interventionDateError = null;
      return true;
    },
    clearInterventionDateError() {
      const interventionDate = this.$refs.general.interventionDate;
      if (interventionDate) {
        this.interventionDateError = null;
      }
    },
    validatePhotos() {
      const photos = this.$refs.dropzone.fileList;
      if (photos.length === 0) {
        this.photoError = this.$t('error.photoRequired');
        return false;
      }
      this.photoError = null;
      return true;
    },
    clearPhotoError() {
      const photos = this.$refs.dropzone.fileList;
      if (photos.length > 0) {
        this.photoError = null;
      }
    },
    async editDetailed() {
      // Validation
      const isLocationValid = this.validateLocation();
      const isInterventionDateValid = this.validateInterventionDate();
      const arePhotosValid = this.validatePhotos();

      if (!isLocationValid || !isInterventionDateValid || !arePhotosValid) {
        return;
      }
      this.$store.commit('loading', true);

      // Other process
      const projectUuid = this.$store.getters.currentProject.uuid;
      const projectRef = this.$store.getters.currentProject.ref;

      // General
      const interventionType = this.$refs.general.typeSelected;
      const interventionDate = this.$refs.general.interventionDate;

      // Images
      const fileList = this.$refs.dropzone.fileList;

      // Existing photo keys
      let photo1Key = this.report.photo1Key || '';
      let photo2Key = this.report.photo2Key || '';
      let photo3Key = this.report.photo3Key || '';

      // Update photo keys if new files are uploaded
      if (fileList.length > 0) photo1Key = fileList[0]?.key || photo1Key;
      if (fileList.length > 1) photo2Key = fileList[1]?.key || photo2Key;
      if (fileList.length > 2) photo3Key = fileList[2]?.key || photo3Key;

      // Map
      const drawingKey = this.$refs.locationMap.drawingKey;

      const clgStyle = 'color: red;';
      console.log('%c' + 'fileList', clgStyle);
      console.log(fileList);
      console.log('photo1Key', photo1Key);
      console.log('photo2Key', photo2Key);
      console.log('photo3Key', photo3Key);
      console.log('%c' + 'drawingKey', clgStyle);
      console.log(drawingKey);

      // Reference
      const buildingUuid = this.$store.getters.building.uuid;
      const buildingLabel = this.$store.getters.building.label;
      const level = this.$store.getters.level;
      const serial = this.$store.getters.serial;
      const zone = this.$store.getters.zone;
      const definition = this.$store.getters.definition.value;
      const reference = {
        buildingUuid,
        buildingLabel,
        level,
        serial,
        zone,
        definition,
      };

      // Location
      const locationValue = this.$refs.localisationReference.locationValue;
      let locationInt = false;
      let locationExt = false;
      let locationCeiling = false;
      let locationFloor = false;
      let locationFacade = false;
      let locationRoof = false;
      let locationServiceShaft = false;
      if (locationValue.includes('locationInt')) locationInt = true;
      if (locationValue.includes('locationExt')) locationExt = true;
      if (locationValue.includes('locationCeiling')) locationCeiling = true;
      if (locationValue.includes('locationFloor')) locationFloor = true;
      if (locationValue.includes('locationFacade')) locationFacade = true;
      if (locationValue.includes('locationRoof')) locationRoof = true;
      if (locationValue.includes('locationServiceShaft'))
        locationServiceShaft = true;
      const location = {
        locationInt,
        locationExt,
        locationCeiling,
        locationFloor,
        locationFacade,
        locationRoof,
        locationServiceShaft,
      };

      // Marker
      const marker = {
        x: this.$refs.locationMap.marker.percent.x,
        y: this.$refs.locationMap.marker.percent.y,
        isCenter: this.$refs.locationMap.isCenter,
      };

      // Description (program)
      const program = this.$refs.program.program;

      // Description (others)
      const description = this.$refs.program.description;
      const standard = this.$refs.program.standard;
      const correctiveAction = this.$refs.program.correctiveAction;

      // Description (remarks)
      const remarksValue = this.$refs.program.remarksValue;
      const remarksOptions = this.$refs.program.remarksOptions;
      let remarkLabels = '';
      remarksValue.forEach((value) => {
        let obj = remarksOptions.find((o) => o.value === value);
        remarkLabels += obj.label + ' ';
      });
      const remarks = { values: remarksValue, labels: remarkLabels };

      // Severity
      const probability = this.$refs.priority.probabilitySelected;
      const severity = this.$refs.priority.severitySelected;
      const priority = this.$refs.priority.priority;

      const reportUuid = this.report.uuid; // Assuming `report` has `uuid`

      axiosResource
        .put(`/reports/detailed/${projectUuid}/${reportUuid}`, {
          data: {
            projectRef,
            interventionType,
            interventionDate,
            fileList,
            drawingKey,
            reference,
            location,
            marker,
            program,
            remarks,
            description,
            standard,
            correctiveAction,
            probability,
            severity,
            priority,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.$store.commit('loading', false);
            swalBootsrap
              .fire({
                title: this.$t('success.done'),
                showConfirmButton: false,
                icon: 'success',
                timer: 1500,
              })
              .then(() => {
                this.$store.commit('loading', true);
                this.$router.push({ name: 'Explore' });
              });
          }
        })
        .catch((err) => {
          this.$globalSwal.error(err.response.data.msg);
        });
    },
    editQuick() {},
    publishQuick() {
      this.$store.commit('loading', true);
      // Validation
      const isLocationValid = this.validateLocation();
      const isInterventionDateValid = this.validateInterventionDate();

      if (!isLocationValid || !isInterventionDateValid) {
        return;
      }

      // Other process
      const projectRef = this.$store.getters.currentProject.ref;
      const projectUuid = this.$store.getters.currentProject.uuid;
      const interventionDate = this.$refs.general.interventionDate;
      const quickInteraction = this.purpose;
      // Reference
      const buildingUuid = this.$store.getters.building.uuid;
      const buildingLabel = this.$store.getters.building.label;
      const level = this.$store.getters.level;
      const serial = this.$store.getters.serial;
      const zone = this.$store.getters.zone;
      const definition = this.$store.getters.definition.value;
      const reference = {
        buildingUuid,
        buildingLabel,
        level,
        serial,
        zone,
        definition,
      };
      console.log(reference);
      // Location
      const drawingKey = this.$refs.locationMap.drawingKey;
      const marker = {
        x: this.$refs.locationMap.marker.percent.x,
        y: this.$refs.locationMap.marker.percent.y,
        isCenter: this.$refs.locationMap.isCenter,
      };
      axiosResource
        .post(`/reports/quick/${projectUuid}/${projectRef}`, {
          data: {
            projectRef,
            interventionDate,
            quickInteraction,
            drawingKey,
            reference,
            marker,
          },
        })
        .then((res) => {
          console.log(res.data.msg);
          console.log(res.data.results);
          if (res.data.success) {
            this.$store.commit('loading', false);
            swalBootsrap
              .fire({
                title: this.$t('success.done'),
                showConfirmButton: false,
                icon: 'success',
                timer: 1500,
              })
              .then(() => {
                // Reset filters so the report can appear in Explore
                // this.$store.commit('setPriority', priority);
                // Go to Explore
                // this.$router.go(-1);
                this.$router.push({ name: 'Explore' });
              });
          }
        })
        .catch((err) => {
          this.$store.commit('loading', false);
          this.loading = false;
          this.$globalSwal.error(err.response.data.msg);
        });
    },
    publishDetailed() {
      // Validation
      const isLocationValid = this.validateLocation();
      const isInterventionDateValid = this.validateInterventionDate();
      const arePhotosValid = this.validatePhotos();

      if (!isLocationValid || !isInterventionDateValid || !arePhotosValid) {
        return;
      }
      this.$store.commit('loading', true);

      // Other process
      const projectUuid = this.$store.getters.currentProject.uuid;
      const projectRef = this.$store.getters.currentProject.ref;
      // General
      const interventionType = this.$refs.general.typeSelected;
      const interventionDate = this.$refs.general.interventionDate;
      // Images
      const fileList = this.$refs.dropzone.fileList;
      fileList.forEach((element) => {
        console.log(element.key);
      });
      // Reference
      const buildingUuid = this.$store.getters.building.uuid;
      const buildingLabel = this.$store.getters.building.label;
      const level = this.$store.getters.level;
      const serial = this.$store.getters.serial;
      const zone = this.$store.getters.zone;
      const definition = this.$store.getters.definition.value;
      const reference = {
        buildingUuid,
        buildingLabel,
        level,
        serial,
        zone,
        definition,
      };
      // Location (endroit)
      const locationValue = this.$refs.localisationReference.locationValue;
      let locationInt = false;
      let locationExt = false;
      let locationCeiling = false;
      let locationFloor = false;
      let locationFacade = false;
      let locationRoof = false;
      let locationServiceShaft = false;
      if (locationValue.includes('locationInt')) locationInt = true;
      if (locationValue.includes('locationExt')) locationExt = true;
      if (locationValue.includes('locationCeiling')) locationCeiling = true;
      if (locationValue.includes('locationFloor')) locationFloor = true;
      if (locationValue.includes('locationFacade')) locationFacade = true;
      if (locationValue.includes('locationRoof')) locationRoof = true;
      if (locationValue.includes('locationServiceShaft')) {
        locationServiceShaft = true;
      }
      const location = {
        locationInt,
        locationExt,
        locationCeiling,
        locationFloor,
        locationFacade,
        locationRoof,
        locationServiceShaft,
      };
      // Location
      const drawingKey = this.$refs.locationMap.drawingKey;
      const marker = {
        x: this.$refs.locationMap.marker.percent.x,
        y: this.$refs.locationMap.marker.percent.y,
        isCenter: this.$refs.locationMap.isCenter,
      };
      // Description (program)
      const clgStyle = 'color: red;';
      console.log('%c' + 'PROGRAM', clgStyle);
      const program = this.$refs.program.program;
      console.log(program);
      // console.log(remarks);
      console.log('---------------------------------------------');
      // Description (others)
      const description = this.$refs.program.description;
      const standard = this.$refs.program.standard;
      const correctiveAction = this.$refs.program.correctiveAction;
      console.log('%c' + 'DESCRIPTION', clgStyle);
      console.log(description);
      console.log('%c' + 'STANDARD', clgStyle);
      console.log(standard);
      console.log('%c' + 'CORRECTIVE ACTION', clgStyle);
      console.log(correctiveAction);
      // Description (remarks)
      const remarksValue = this.$refs.program.remarksValue;
      const remarksOptions = this.$refs.program.remarksOptions;
      let remarkLabels = '';
      remarksValue.forEach((value) => {
        let obj = remarksOptions.find((o) => o.value === value);
        console.log(obj.label);
        remarkLabels += obj.label + ' ';
      });
      const remarks = { values: remarksValue, labels: remarkLabels };
      console.log('%c' + 'REMARKS', clgStyle);
      // Severity
      const probability = this.$refs.priority.probabilitySelected;
      const severity = this.$refs.priority.severitySelected;
      const priority = this.$refs.priority.priority;

      console.log(program);
      console.log(remarks);
      // console.log(
      //   projectRef,
      //   interventionType,
      //   interventionDate,
      //   fileList,
      //   drawingKey,
      //   reference,
      //   location,
      //   marker,
      //   program,
      //   remarks,
      //   description,
      //   standard,
      //   correctiveAction,
      //   probability,
      //   severity,
      //   priority,
      // );
      // return;

      axiosResource
        .post(`/reports/detailed/${projectUuid}/${projectRef}`, {
          data: {
            projectRef,
            interventionType,
            interventionDate,
            fileList,
            drawingKey,
            reference,
            location,
            marker,
            program,
            remarks,
            description,
            standard,
            correctiveAction,
            probability,
            severity,
            priority,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.$store.commit('loading', false);
            swalBootsrap
              .fire({
                title: this.$t('success.done'),
                showConfirmButton: false,
                icon: 'success',
                timer: 1500,
              })
              .then(() => {
                this.$store.commit('loading', true);
                // COPY HERE MATE
                // ================== save last
                // get family number

                // Reinint search (I DON't KNOW WHAT THIS IS FOR)
                this.$store.commit('initPage');
                this.$store.commit('initReports', 'Create');
                this.$store.commit('resetQttLoaded');
                // Go to Explore
                // this.$router.go(-1);
                this.$router.push({ name: 'Explore' });
              });
          }
        })
        .catch((err) => {
          this.$store.commit('loading', false);
          this.loading = false;
          this.$globalSwal.error(err.response.data.msg);
        });
    },
  },
};
</script>

<style>
.error-create {
  font-size: 16px;
}
/* .create-container {
  margin: 0px 1px 100px 1px !important;
  width: 100vw !important;
} */
.btn-secondary {
    color: #606266;
    background-color: #fff;
    border: 1px solid #DCDFE6;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle{
  color: #409EFF;
  background-color: #ecf5ff;
  border-color: #c6e2ff;
}


.btn-secondary:hover,
.btn-secondary:focus {
  color: #409EFF;
  background-color: #fff;
  border-color: #DCDFE6;
}

.btn:active,
.btn.active {
    -webkit-box-shadow: none;
            box-shadow: none;
}

.btn-group-vertical {
  width: 100%;
}


div.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
}

/* .custom-checkbox {
  padding-bottom: 1rem !important;
} */




.btn-container {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(229, 232, 235);
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 14px;
  padding-bottom: 22px;
  background-color: white;
}
.btn-jumbo {
  border-radius: 12px;
  padding: 17px 24px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.01em;
}
.btn-white-blue-text{
  color: rgb(32, 129, 226);
}
</style>